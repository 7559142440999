import React, { createContext, useState } from 'react'

const AnimationContext = createContext(false)

export function AnimationProvider({ children }) {
  const [visitedFirstTime, setVisitedFirstTime] = useState(false)
  const value = { visitedFirstTime, setVisitedFirstTime }
  return <AnimationContext.Provider value={value}>{children}</AnimationContext.Provider>
}

export default AnimationContext